import React from 'react';
import Link from 'next/link';

import { openCrispChat } from 'utils/helpers';
import Facebook from 'public/icons/facebook.svg';
import Twitter from 'public/icons/twitter-grey.svg';
import Instagram from 'public/icons/instagram.svg';
import useIsAdvertiser from 'hooks/useIsAdvertiser';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import AdvertiserFooter from './AdvertiserFooter';
import InfluencerFooter from './InfluencerFooter';
import styles from 'styles/components/home/Footer.module.scss';

const Footer = () => {
  const isLoggedIn = useIsLoggedIn();
  const isAdvertiser = useIsAdvertiser();

  if (isLoggedIn && isAdvertiser) return <AdvertiserFooter />;
  if (isLoggedIn && !isAdvertiser) return <InfluencerFooter />;

  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        <Link href="/">Home</Link>

        <Link href="/sign-up/advertiser">Buy</Link>

        <Link href="/sign-up/influencer">Sell</Link>

        <a href="#" onClick={() => openCrispChat()}>
          Contact
        </a>

        <Link href="/login">Log In</Link>

        <Link href="/sign-up">Sign Up</Link>

        <Link href="/terms">Terms Of Use</Link>

        <Link href="/privacy">Privacy Policy</Link>
      </div>

      <div className={styles.socialNetworks}>
        <a href="https://www.facebook.com/gigsocialapp/" target="blank">
          <Facebook width={28} height={28} alt="Facebook" className={styles.facebook} />
        </a>

        <a href="https://twitter.com/gigsocialapp" target="blank">
          <div className={styles.twitter}>
            <Twitter width={17} height={17} alt="Twitter" />
          </div>
        </a>

        <a href="https://www.instagram.com/gig.social/" target="blank">
          <Instagram width={29} height={29} alt="Instagram" className={styles.instagram} />
        </a>
      </div>

      <p className={styles.copyright}>
        © {new Date().getFullYear()} GigSocial Inc. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
