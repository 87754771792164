import React from 'react';
import styles from 'styles/components/ErrorBoundary.module.scss';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState(prevState => ({
      ...prevState,
      errorInfo,
    }));

    window.Rollbar?.error('ErrorBoundary caught exception', {
      error,
      errorInfo,
      message: error.message,
      stack: error.stack,
      componentStack: errorInfo?.componentStack,
    });
  }

  render() {
    const { hasError, error, errorInfo } = this.state;

    if (hasError) {
      return (
        <div className={styles.container}>
          <div className={styles.card}>
            <h1 className={styles.title}>Oops! Something went wrong. 😱</h1>
            <p className={styles.message}>
              We're sorry, but an unexpected error occurred. The GigSocial team has been notified.
              <br />
              <br />
              Please clear your cookies and refresh the page or try a different browser.
            </p>

            {error && (
              <details className={styles.details} open>
                <summary className={styles.summary}>View technical details</summary>

                <div className={styles.stackSection}>
                  <h2 className={styles.stackTitle}>JavaScript Stack Trace</h2>
                  <pre className={styles.stacktrace}>
                    <code>{error.stack}</code>
                  </pre>
                </div>

                {errorInfo?.componentStack && (
                  <div className={styles.stackSection}>
                    <h2 className={styles.stackTitle}>React Component Stack</h2>
                    <pre className={styles.stacktrace}>
                      <code>{errorInfo.componentStack}</code>
                    </pre>
                  </div>
                )}
              </details>
            )}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
