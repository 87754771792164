import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getRequest } from 'utils/api';
import { formatPrice } from 'utils/helpers';
import RadioButton from 'components/RadioButton';
import ExternalLink from 'components/ExternalLink';
import WalletIcon from 'public/icons/wallet.svg';

const useStyles = makeStyles({
  root: {
    margin: 0,
  },
});

const balanceStyle = {
  display: 'flex',
  alignItems: 'center',
  color: '#5b00db',
  fontFamily: 'Avenir Heavy',
};

const PaymentOptions = ({ styles, paymentOption, setPaymentOption, totalPrice }) => {
  const [wallet, setWallet] = useState({});
  const isLoggedIn = useSelector(state => state.auth.loggedIn);

  useEffect(() => {
    if (!isLoggedIn) return;

    getRequest({
      endpoint: 'wallets/current',
    })
      .then(wallet => {
        setWallet(wallet);
      })
      .catch(() => {});
  }, [isLoggedIn]);

  const classes = useStyles();

  const handleChange = event => setPaymentOption(event.target.value);

  return (
    <div className={styles.payment}>
      <h3>HOW YOU'LL PAY</h3>

      <RadioGroup name="payment" value={paymentOption} onChange={handleChange}>
        {isLoggedIn && (
          <div className={styles.option}>
            <FormControlLabel
              className={classes.root}
              value="wallet"
              control={<RadioButton />}
              label="Wallet"
              disabled={+wallet.available_balance < totalPrice || !wallet.ready}
            />

            {!wallet.ready && (
              <ExternalLink
                className="color-main underline"
                mobileUrl="https://gigsocial.crisp.help/en-us/article/how-do-i-use-funds-in-my-gigsocial-wallet-pa7cdj/"
                desktopUrl="https://gigsocial.crisp.help/en-us/article/how-do-i-use-funds-in-my-gigsocial-wallet-pa7cdj/"
                target="_blank"
                rel="noopener noreferrer"
                page="How to use wallet"
                preview="url"
              >
                How to use wallet
              </ExternalLink>
            )}

            <div style={balanceStyle}>
              <span>{formatPrice(wallet.cached_true_balance)}</span>
              <WalletIcon width={60} height={30} />
            </div>
          </div>
        )}

        <div className={styles.option}>
          <FormControlLabel
            className={classes.root}
            value="card"
            control={<RadioButton />}
            label="Card"
          />
          <Image src="/icons/payment/card.svg" width={184} height={39} alt="Card" unoptimized />
        </div>

        <div className={styles.option}>
          <FormControlLabel
            className={classes.root}
            value="apple-pay"
            control={<RadioButton />}
            label="Pay with Apple Pay"
          />

          <Image
            src="/icons/payment/apple-pay.svg"
            width={60}
            height={39}
            alt="Apple Pay"
            unoptimized
          />
        </div>

        <div className={styles.option}>
          <FormControlLabel
            className={classes.root}
            value="google-pay"
            control={<RadioButton />}
            label="Pay with Google Pay"
          />

          <Image
            src="/icons/payment/google-pay.svg"
            width={60}
            height={39}
            alt="Google Pay"
            unoptimized
          />
        </div>

        <div className={styles.option}>
          <FormControlLabel
            className={classes.root}
            value="pay-pal"
            control={<RadioButton />}
            label="Pay with PayPal"
          />

          <Image
            src="/icons/payment/pay-pal.svg"
            width={100}
            height={39}
            alt="PayPal"
            unoptimized
          />
        </div>

        <div className={styles.option}>
          <FormControlLabel
            className={classes.root}
            value="bitcoin"
            control={<RadioButton />}
            label="Pay with Bitcoin"
          />

          <Image
            src="/icons/payment/bitcoin.svg"
            width={130}
            height={39}
            alt="Bitcoin"
            unoptimized
          />
        </div>
      </RadioGroup>
    </div>
  );
};

PaymentOptions.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  paymentOption: PropTypes.string.isRequired,
  styles: PropTypes.object,
  setPaymentOption: PropTypes.func,
};

PaymentOptions.defaultProps = {
  styles: {},
  setPaymentOption: () => {},
};

export default PaymentOptions;
