import React from 'react';
import Link from 'next/link';
import { MenuItem, MenuList } from '@material-ui/core';
import clsx from 'clsx';

import InstagramIcon from 'public/icons/network/outline/instagram-black.svg';
import TelegramIcon from 'public/icons/network/outline/telegram-black.svg';
import styles from 'styles/components/sidebar/DropdownList.module.scss';

const Footer = ({ handleClose, handleLogout }) => (
  <MenuList className={clsx(styles.menuList, styles.bottomSection)}>
    <Link href="/terms" passHref>
      <MenuItem onClick={handleClose}>Terms</MenuItem>
    </Link>

    <div className={styles.straightLine} />

    <Link href="/privacy" passHref>
      <MenuItem onClick={handleClose}>Privacy policy</MenuItem>
    </Link>

    <div className={styles.straightLine} />

    <div onClick={handleLogout}>
      <MenuItem onClick={handleClose}>Logout</MenuItem>
    </div>

    <div className={styles.straightLine} />

    <a href="https://www.instagram.com/gig.social/" target="_blank" rel="noopener noreferrer">
      <MenuItem>
        <InstagramIcon className={styles.bottomIcon} width={24} height={24} />
      </MenuItem>
    </a>

    <div className={styles.straightLine} />

    <a href="https://t.me/GigSocial" target="_blank" rel="noopener noreferrer">
      <MenuItem>
        <TelegramIcon className={styles.bottomIcon} width={24} height={24} />
      </MenuItem>
    </a>
  </MenuList>
);

export default Footer;
