import { useDispatch } from 'react-redux';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { openModal } from 'features/utilsSlice';
import isEmpty from 'lodash/isEmpty';

const validImagePreviews = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];

const ExternalLink = ({
  mobileUrl,
  desktopUrl,
  page,
  children,
  preview = 'url',
  linkStyle,
  stopPropagation = false,
  ...rest
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const url = isMobile ? mobileUrl : desktopUrl;

  const handleClick = e => {
    if (isMobile && (preview === 'url' || validImagePreviews.includes(preview))) {
      e.preventDefault();
      e.stopPropagation();
      dispatch(
        openModal({ name: 'External Link', data: { url, page, isImage: preview !== 'url' } })
      );
    } else if (stopPropagation) {
      e.stopPropagation();
    }
  };

  return (
    <a style={!isEmpty(linkStyle) ? linkStyle : {}} onClick={handleClick} href={url} {...rest}>
      {children}
    </a>
  );
};

export default ExternalLink;
